import React from 'react';

function DescriptionInput({ onNameChange }) {
  return (
    <div className="input-container">
      <textarea
        id="product-description"
        className="form-textarea2"
        placeholder="Enter your product description here..."
        onChange={(event) => onNameChange(event.target.value)}
      />
    </div>
  );
}

export default DescriptionInput;
