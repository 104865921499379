import logo from './logo.svg';
import './App.css';
import './css/formation-4140cf.webflow.css';
import './css/normalize.css';
//import './css/webflow.css';
//import SliderBar from './Slider';
import PlatformSelector from './PlatformSelector';
import MathComponent from './MathComp';
import DropdownMenu from './OutreachGoals';
import EditableList from './EditableList';
import Button from 'react-bootstrap/Button';
import TotalCost from './CreditCard';
import plusIcon from './images/icons8-plus.svg';
import loadingGif from './images/tAp4jVTlJedVO0CqwX3gMnMUE.gif';
import { useState, useEffect, useCallback } from 'react';
import EditableRow from './EditableRow';
import SelectableBoxes from './Size.js';
import TextInput from './InputText.js';
import PromoInput from './PromoText.js';
import LinkInput from './LinkField.js';
import DescriptionInput from './DescriptionField.js';
import EmailInput from './EmailField.js';
import TargetInput from './TargetField.js';

//const queryParams = new URLSearchParams(window.location.search);
//const sessionID = queryParams.get('SessionID');
//const userID = queryParams.get('UserID');
const token = "onb";
console.log(token)

const error = false;

function App() {
  const [price, setPrice] = useState(0);
  const [platforms, setPlatforms] = useState(['TikTok']);
  const [sliderValue, setSliderValue] = useState(0);
  const [outreachGoal, setOutreachGoal] = useState('');
  const [selections, setSelections] = useState({
    //nano: true,
    macro: true,
    micro: true,
  });

  const [email, setEmail] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');
  const [target, setTarget] = useState('');

  const onEmailChange = (name) => {
    setEmail(name);
  };

  const onLinkChange = (name) => {
    setLink(name);
  };

  const onDescriptionChange = (name) => {
    setDescription(name);
  };

  const onTargetChange = (name) => {
    setTarget(name);
  };

  const [emailSignatureName, setEmailSignatureName] = useState('');
  const [promo, setPromo] = useState('');

  // Function to handle changes to the text input
  const onNameChange = (name) => {
    setEmailSignatureName(name);
  };

  const [discount, setDiscount] = useState(0);  // Initialize discount state
  const [promoStatus, setPromoStatus] = useState('');

  const [loadingS, setLoadingS] = useState(false);  // State to track loading status

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const checkPromoCode = (promoCode) => {
    if (!promoCode || promoCode.length <= 3) {
      setLoadingS(true);
      setTimeout(() => {
        setPromoStatus('');
        setLoadingS(false);
      }, 500); // Delay clearing the status to show the spinner
      return;
    }

    setLoadingS(true);
    fetch('https://yu6v3zsjwda4t6bpuoek5wymqy0irvqw.lambda-url.us-east-2.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ promo_code: promoCode })
    })
    .then(response => response.json())
    .then(data => {
      setLoadingS(false);
      if (data.discount > 0) {
        setDiscount(data.discount);
        setPromoStatus('valid');
      } else {
        setDiscount(0);
        setPromoStatus('invalid');
      }
    })
    .catch(error => {
      console.error('Error verifying promo code:', error);
      setDiscount(0);
      setPromoStatus('invalid');
      setLoadingS(false);
    });
  };

  const debouncedCheckPromoCode = useCallback(debounce(checkPromoCode, 300), []);

  const onPromoChange = (promoCode) => {
    setPromo(promoCode);
    debouncedCheckPromoCode(promoCode);
  };
  
  // Handler to update state based on selections in child component
  const handleSelectionChange = (updatedSelections) => {
    setSelections(updatedSelections);
  };
// Function to update the outreach goal
const handleOutreachGoalChange = (selectedGoal) => {
  setOutreachGoal(selectedGoal);
};

  // Callback to update the price in App's state
  const handlePriceChange = (newPrice) => {
      setPrice(newPrice);
  };

  // Callback to update the platforms in App's state
  const handlePlatformChange = (newPlatforms) => {
      setPlatforms(newPlatforms);
  };

  // Callback to update the slider value in App's state
  const handleSliderChange = (newSliderValue) => {
      setSliderValue(newSliderValue);
  };

  if (error) {
    return <p>Error loading content: {error}</p>;
  }
  //<img src={loadingGif} className="resize-image" />
  /*
  if (combinedInfo.length === 0) {
    return(
    <section className="load_section">
    <div className="sxd"><p className='loading_p'>Loading</p><div className="loading-dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
  </div>
  </section>
    
    )
  }
  */
  const calculateMaxRows = () => {
   //console.log(platforms.length);
    // Adjust the max slider value based on the number of selected platforms
    const influencerTypesCount = Object.values(selections).filter(isSelected => isSelected).length;

    const t_max = influencerTypesCount === 0 ? 120 : influencerTypesCount * 40;
    const maxSliderValue = platforms.length === 2 ? 2*t_max : t_max;
    // Calculate the percentage of the current slider value against the max value
    const sliderPercentage = (sliderValue / maxSliderValue) * 100;
    //console.log(sliderPercentage);
    // Calculate additional rows based on each 10% increase in slider value
    let additionalRows = Math.floor(sliderPercentage / 10);
    // Ensure a minimum of 2 elements
    let calculatedRows = additionalRows;
    // Cap the number of rows at 10 as a maximum
    
    return Math.min(calculatedRows, 10);
  };
  const maxNumRows = calculateMaxRows();

  //<div className="head-gif"><img src={loadingGif} className="resize-image" /></div>
  return (
    <div className="App">
      <section className="section">
      <div className="w-layout-blockcontainer container w-container">
        <div className="header-centered-div">
        
          <h1 className="heading-2">Customize Your Influencer Marketing Package</h1>
          <p className="paragraph">Tailor your package to fit your needs. Choose the number of influencers, your preferred platform, and pick your outreach goals.</p>
        </div>
        <div className="outreach-goal">
          <h2 className="heading-4">Email</h2>
          <p className="paragraph-2">Please leave the email you want to receive the results with.</p>
          <EmailInput onNameChange={onEmailChange}/>
          
        </div>
        <div className="outreach-goal">
          <h2 className="heading-4">Product Information</h2>
          <p className="paragraph-2">Please leave the link to your product or service below (Optional)</p>
          <LinkInput onNameChange={onLinkChange}/>
          <p className="paragraph-2" style={{ paddingTop: '25px' }}>Enter a detailed description of your product here. Include features, benefits, and any other relevant information.</p>
          <DescriptionInput onNameChange={onDescriptionChange}/>
          <p className="paragraph-2" style={{ paddingTop: '25px' }}>Describe or list the intended audience(s) you want to reach. The more information you give, the better our results will be!</p>
          <TargetInput onNameChange={onTargetChange}/>
        </div>
          <MathComponent 
            selections={selections}
            onSliderChange={handleSliderChange}
                onPlatformChange={handlePlatformChange}
                onPriceChange={handlePriceChange}></MathComponent>
        <div className="outreach-goal">
          <h2 className="heading-4">Outreach Goals</h2>
          <p className="paragraph-2">Select the desired outreach goal for your influencer marketing campaign.</p>
          <DropdownMenu onSelectValue={handleOutreachGoalChange}></DropdownMenu>
          <p className="paragraph-2" style={{ paddingTop: '25px' }}>Type the name that will be used to sign the outreach emails you send to influencers (Optional)</p>
          <TextInput onNameChange={onNameChange} />
        </div>
        <div className="outreach-goal">
          <h2 className="heading-4">Influencer Size</h2>
          <p className="paragraph-2">Select typical views per post based on recent activity</p>
          <SelectableBoxes onSelectionChange={handleSelectionChange}></SelectableBoxes>
        </div>
        
        <div className="outreach-goal">
          <h2 className="heading-4">Apply Promo Code (Optional)</h2>
         
          <PromoInput onNameChange={onPromoChange} promoStatus={promoStatus} loading={loadingS}/>
        </div>
        <TotalCost email={email} link={link} description={description} target={target} discount={discount} promoCode={promo} emailName={emailSignatureName} cost={price} goals={outreachGoal} numInfluencers={sliderValue} selectedPlatforms={platforms} token_string={token} selectedSizes={selections}> </TotalCost>

      </div>
    </section>
    </div>
  );
}

export default App;