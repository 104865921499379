import React from 'react';

function TargetInput({ onNameChange }) {
  return (
    <div className="input-container">
      <textarea
        id="target-aud"
        className="form-textarea2"
        placeholder="Describe your target audiences..."
        onChange={(event) => onNameChange(event.target.value)}
      />
    </div>
  );
}

export default TargetInput;
