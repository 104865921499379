import React from 'react';

function EmailInput({ onNameChange }) {
  return (
    <div className="input-container">
      <input
        type="text"
        id="email-link"
        className="form-input"
        placeholder="email123@gmail.com"
        onChange={(event) => onNameChange(event.target.value)}
      />
    </div>
  );
}

export default EmailInput;
