import React from 'react';

function LinkInput({ onNameChange }) {
  return (
    <div className="input-container">
      <input
        type="text"
        id="product-link"
        className="form-input"
        placeholder="example-product.com"
        onChange={(event) => onNameChange(event.target.value)}
      />
    </div>
  );
}

export default LinkInput;
